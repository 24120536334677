import { InitialPaginationParams } from '../../../api/common/common.types';
import { Column } from '../../table/table-head/CustomTableHead.types';

export const initialPaginationState: InitialPaginationParams = {
  defaultCurrentPage: 1,
  defaultOrderBy: 'id',
  defaultOrder: 'desc',
  defaultRowsPerPage: 25,
};

export const TABLE_COLUMNS_CONFIG: Column[] = [
  {
    id: 'title',
    label: 'portfolioPlans.listTitle',
    width: '20%',
  },
  {
    id: 'description',
    label: 'portfolioPlans.description',
    width: '20%',
    isNotSortable: true,
  },
  {
    id: 'isPaid',
    label: 'portfolioPlans.form_fields.ispaid.placeholder',
    width: '20%',
  },
  {
    id: 'actions',
    label: 'masterClass.actions',
    width: '10%',
    isNotSortable: true,
  },
];
