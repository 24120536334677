import React from 'react';
import { Button, TableRow, Stack, IconButton, Tooltip } from '@mui/material';
import { PortfolioTransactionsRowProps } from './PortfolioRow.types';
import { TableCellStyle } from './PortfolioRow.styles';
import EyeIcon from '../../../svgComponents/EyeIcon';

export default function PortfolioTransactionsTableRow({ row, selected, columnsConfig }: PortfolioTransactionsRowProps) {
  const { id, price, purchaseDate } = row;

  const formatDate = (dateString?: string) => {
    if (!dateString) return '-'; // Return a placeholder if the date is undefined
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Extracts the date part
  };

  return (
    <TableRow key={id} hover selected={selected} sx={{ backgroundColor: '#ffffff' }}>
      {columnsConfig.map((column) => (
        <TableCellStyle key={column.id} align={column.align || 'left'} width={column.width}>
          {column.id === 'price' ? (
            `€ ${price}`
          ) : column.id === 'purchaseDate' ? (
            formatDate(purchaseDate)
          ) : column.id !== 'actions' ? (
            String(row[column.id as keyof typeof row])
          ) : (
            <Stack direction="row" spacing={2}>
              <Tooltip title="View Details" arrow>
                <IconButton
                  sx={{
                    padding: '0px !important',
                  }}
                >
                  <EyeIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </TableCellStyle>
      ))}
    </TableRow>
  );
}
