/* eslint-disable react/react-in-jsx-scope */
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddDialogProps } from './add-dialog.types';
import { AddDialogStyle } from './add-dialog.styles';
import CloseIcon from '../../../svgComponents/closeIcon';
import ViewPortfolioPlans from '../form/ViewPortfolioPlan';
import PortfolioPlanForm from '../form/PortfolioPlanForm';

export default function AddDialog({ open, onClose, currentPortfolioPlans, isUpdate, isEyeIconClicked }: AddDialogProps) {
  const { t } = useTranslation();
  return (
    <AddDialogStyle open={open} fullWidth sx={{ '& .MuiDialog-paper': { height: 'auto !important' } }} className="artgapi-wrapper">
      <Stack display={'flex'} direction={'row'} justifyContent={'space-between'} padding={2}>
        <Typography variant={'h5'}>
          {isEyeIconClicked
            ? t('portfolioPlans.view_portfolio_title')
            : isUpdate
            ? t('portfolioPlans.update_portfolio_title')
            : t('portfolioPlans.add_portfolio_title')}
        </Typography>
        <IconButton aria-label="close" color="primary" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      {isEyeIconClicked ? (
        <ViewPortfolioPlans onClose={onClose} currentPortfolioPlans={currentPortfolioPlans} isUpdate={isUpdate} isEyeIconClicked={isEyeIconClicked} />
      ) : (
        <PortfolioPlanForm onClose={onClose} currentPortfolioPlans={currentPortfolioPlans} isUpdate={isUpdate} isEyeIconClicked={isEyeIconClicked} />
      )}
    </AddDialogStyle>
  );
}
