import * as yup from 'yup';

export const CreatePortfolioSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .max(50, 'portfolioPlans.form_fields.title.max_length_error_message')
    .required('portfolioPlans.form_fields.title.required_error_message'),

  description: yup.string().trim().required('portfolioPlans.form_fields.description.required_error_message'),

  isPaid: yup
    .number()
    .typeError('portfolioPlans.form_fields.ispaid.required_error_message')
    .required('portfolioPlans.form_fields.ispaid.required_error_message'),

  price: yup
    .number()
    .typeError('portfolioPlans.form_fields.price.required_error_message')
    .required('portfolioPlans.form_fields.price.required_error_message'),

  duration: yup
    .number()
    .typeError('portfolioPlans.form_fields.duration.required_error_message')
    .required('portfolioPlans.form_fields.duration.required_error_message'),
});
