import React, { useEffect, useState, ChangeEvent } from 'react';
import { Box, Container, Table, TableBody, TableContainer, TablePagination, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TablePaginationActions } from '../../table/TablePaginationActions/TablePaginationActions';
import TableLoadingData from '../../table/TableLoadingData';
import TableNoData from '../../table/TableNoData';
import TableHeadCustom from '../../table/table-head/CustomTableHead';
import { useDebounce } from '@uidotdev/usehooks';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../store/rootStore';
import useTable from '../../../hooks/useTable';
import CommonToolbar from '../../../utils/toolbar/CommonToolbar';
import ConfirmDeleteDialog from '../../../utils/confirm-delete-dialog/ConfirmDeleteDialog';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import { AxiosResponse } from 'axios';
import { IPortfolioPlanResponse, IPortfolioPlanState, PortfolioActionTypes } from '../../../store/portfolioPlans/slice/PortfolioPlans-slice.types';
import PortfolioPlansTableRow from './portfolioPlan-row/PortfolioPlansTableRow';
import PortfolioPlan from '../../../models/Portfolio';
import { TABLE_COLUMNS_CONFIG, initialPaginationState } from './PortfolioPlan.constant';
import { portfolioPlanApi } from '../../../store/portfolioPlans/api/PortfolioPlans.api';
import useSnackBar from '../../../hooks/useSnackBar';
import { RootStyle } from '../../../pages/systemusers/SystemUser.styles';
import AddDialog from './add-dialog/AddDialog';

function PortfolioPlanListing() {
  const { t } = useTranslation();
  const [filterKeyword, setfilterKeyword] = useState('');
  const [isEyeIconClicked, setIsEyeIconClicked] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const debouncedSearchTerm = useDebounce(filterKeyword, 500);
  const [currentPortfolioPlans, setCurrentPortfolioPlans] = useState<PortfolioPlan | undefined>(undefined);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, PortfolioActionTypes>>();
  const { data, isLoading } = useSelector<RootState, IPortfolioPlanState>((state: RootState) => state[portfolioPlanApi.reducerPath]);
  const { setPage, onSort, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable(initialPaginationState);
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
  const [isEdit, setIsEdit] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  useEffect(() => {
    dispatch(
      portfolioPlanApi.endpoints.getPortfolioPlanUsers({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
      }),
    );
  }, [order, orderBy, page, rowsPerPage, debouncedSearchTerm, dispatch]);
  const { alertSuccess, alertError } = useSnackBar();

  const onFilterKeyword = (keyword: string) => {
    setfilterKeyword(keyword);
    setPage(1);
  };

  const onShowDetails = async (id?: number, isShow?: boolean) => {
    setIsEdit(false);
    try {
      const response = await sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.PORTFOLIO_PLANS}/${id}`, {}, true, {});
      const responseBody = response.data.subscriptionPortfolio;
      const { title, description, price, isPaid, duration } = responseBody;
      const userData: PortfolioPlan = {
        id: id,
        title: title,
        description: description,
        isPaid: isPaid,
        price: price,
        duration: duration,
      };
      setIsEyeIconClicked(isShow ?? false);
      setCurrentPortfolioPlans(userData);
      setAddDialogOpen(true);
    } catch (err) {
      console.log('error', err);
    }
  };

  const onCloseDialog = () => {
    setAddDialogOpen(false);
    setCurrentPortfolioPlans(undefined);
    setIsEyeIconClicked(false);
    setIsEdit(false);
    setfilterKeyword('');
    dispatch(
      portfolioPlanApi.endpoints.getPortfolioPlanUsers({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
      }),
    );
  };

  const onOpenAddDialog = () => {
    setCurrentPortfolioPlans(undefined);
    setAddDialogOpen(true);
    setIsEdit(false);
  };

  const onCloseConfirmDeleteDialog = () => {
    setSelectedId(undefined);
    setConfirmDeleteOpen(false);
    setIsEyeIconClicked(false);
    setfilterKeyword('');
  };

  const onDeleteUniversity = async () => {
    try {
      if (selectedId) {
        await dispatch(portfolioPlanApi.endpoints.deletePortfolioPlans(selectedId));
        onCloseConfirmDeleteDialog();
        dispatch(alertSuccess(t('portfolioPlans.delete_success_message')));
        const isLastPage = data?.next === null;
        const isOnlyItemOnPage = data?.subscriptionPortfolio?.length === 1;
        if (isLastPage && isOnlyItemOnPage && page > 1) {
          setPage(page - 1);
        }
        dispatch(
          portfolioPlanApi.endpoints.getPortfolioPlanUsers({
            page,
            limit: rowsPerPage,
            orderBy,
            order,
            keyword: debouncedSearchTerm,
          }),
        );
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
    }
  };

  const onOPenEditDialog = (id?: number) => {
    setIsEdit(true);
    sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.PORTFOLIO_PLANS}/${id}`, {}, true, {})
      .then((response: AxiosResponse) => {
        const responseBody = response.data.subscriptionPortfolio;
        const { title, description, price, isPaid, duration } = responseBody;

        const userData: PortfolioPlan = {
          id: id,
          title: title,
          description: description,
          isPaid: isPaid,
          price: price,
          duration: duration,
        };
        setCurrentPortfolioPlans(userData);
      })
      .catch((err) => {
        console.log('error', err);
      });
    setAddDialogOpen(true);
  };

  const onConfirmDelete = (id?: number) => {
    setSelectedId(id);
    setConfirmDeleteOpen(true);
  };

  const isFullFilled = !isLoading && data && data?.subscriptionPortfolio?.length > 0;
  console.log('currentPortfolioPlans', currentPortfolioPlans);

  return (
    <>
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        onClose={onCloseConfirmDeleteDialog}
        onDelete={onDeleteUniversity}
        confirm_delete_title={t('portfolioPlans.confirm_delete_title')}
        confirm_delete_message={t('portfolioPlans.confirm_delete_message')}
      />
      <AddDialog
        open={addDialogOpen}
        onClose={onCloseDialog}
        currentPortfolioPlans={currentPortfolioPlans}
        isUpdate={isEdit}
        isEyeIconClicked={isEyeIconClicked}
      />
      <RootStyle>
        <CommonToolbar
          filterKeyword={filterKeyword}
          onFilterKeyword={(value: string) => onFilterKeyword(value)}
          onOpenAddDialog={onOpenAddDialog}
          title={t('portfolioPlans.title')}
          message={t('portfolioPlans.add')}
        />
        <Container sx={{ minHeight: '70vh' }}>
          <div className="table-block interest-panel">
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={'medium'}>
                <TableHeadCustom order={order} orderBy={orderBy} columnsConfig={TABLE_COLUMNS_CONFIG} onSort={onSort} />
                <TableBody>
                  {isFullFilled &&
                    (data as IPortfolioPlanResponse)?.subscriptionPortfolio?.map((row: PortfolioPlan) => {
                      return (
                        <PortfolioPlansTableRow
                          key={row.id}
                          row={row}
                          selected={false}
                          columnsConfig={TABLE_COLUMNS_CONFIG}
                          onShowDetails={(id, isShow) => onShowDetails(id, true)}
                          onOPenEditDialog={onOPenEditDialog}
                          onConfirmDelete={onConfirmDelete}
                        />
                      );
                    })}
                  <TableNoData isNotFound={!isLoading && data?.subscriptionPortfolio?.length === 0} title={t('portfolioPlans.no_data')} />

                  <TableLoadingData isLoading={isLoading} />
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {isFullFilled && (
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={data ? data.total : 0}
                rowsPerPage={rowsPerPage}
                page={data && page ? page - 1 : 0}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={t('common.rowsperpage')}
                sx={{
                  '& .MuiInputBase-root': {
                    maxWidth: '60px',
                  },
                }}
              />
            </Box>
          )}
        </Container>
      </RootStyle>
    </>
  );
}

export default PortfolioPlanListing;
